.leaflet-right {
    z-index: 1031 !important;
}

.leaflet-left .leaflet-control {
    margin-top: 80px;
    margin-left: 15px !important;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12) !important;
}

.leaflet-right .leaflet-control {
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12) !important;
}

.leaflet-bar a,
.leaflet-bar a:hover {
    width: 30px !important;
    height: 30px !important;
    text-align: center !important;
    vertical-align: middle !important;
    line-height: 30px !important;
    color: #999 !important;
}

.leaflet-retina .leaflet-control-layers-toggle {
    width: 41px !important;
    height: 41px !important;
}

.leaflet-control-layers {
    margin-top: 15px !important;
    margin-right: 15px !important;
}

.leaflet-bottom .leaflet-control {
    background-color: white !important;
}

.circle {
    width: 15px;
    height: 15px;
    float: left;
    margin-right: 10px;
}

.legend {
    width: 145px;
    padding: 10px;
    margin-right: 15px !important;
    border-radius: 5px !important;
}

.MapNav {
    font-size: large;
    font-weight: 500;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1);
    margin-left: 10px !important;
}

@media only screen and (max-width: 959px) {
    .leaflet-left .leaflet-control {
        margin-top: 70px !important;
    }

    .leaflet-control-layers {
        margin-top: 70px !important;
    }

    .legend {
        margin-bottom: calc(68%) !important;
    }

    .leaflet-bar a,
    .leaflet-bar a:hover {
        width: 30px !important;
        height: 30px !important;
        line-height: 30px !important;
    }

    .leaflet-retina .leaflet-control-layers-toggle {
        width: 35px !important;
        height: 35px !important;
    }

    .MapNav {
        margin-left: 5px !important;
    }

    .easy-button-container {
        width: 35px !important;
        height: 35px !important;
    }

    .easy-button-container button {
        width: 35px !important;
        height: 35px !important;
    }

    /* #sctabimg {
        bottom: 60px !important;
    } */

    #counter_cair {
        display: none !important;
    }

    .leaflet-container .leaflet-control-search {
        margin-top: 10px !important;
    }
}

.leaflet-popup-content {
    margin: 0 !important;
}

.leaflet-popup-content p {
    margin: 6px 6px 0 0 !important;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12) !important;
}

.leaflet-touch .leaflet-bar,
.leaflet-touch .leaflet-control-layers {
    border: none !important;
}

.leaflet-touch .leaflet-bar a:first-child {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.leaflet-touch .leaflet-bar a:last-child {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.popup_aqi {
    width: 100% !important;
    min-width: 240px !important;
    height: 140px !important;
    padding: 10px !important;
    margin: 0 !important;
}

.popup_content {
    width: 100%;
    float: left;
}

.popup_title {
    float: left;
    font-weight: 600;
    margin-right: 10px !important;
}

.popup_value {
    float: left;
}

.sider_logo_img {
    width: 85%;
}

.legend_remark {
    margin: 10px 0 0 !important;
    font-size: x-small;
}

.marker-cluster-small {
    background-color: #fff !important;
}

.marker-cluster-medium {
    background-color: #fff !important;
}

.marker-cluster-large {
    background-color: #fff !important;
}

.marker-cluster span {
    font-weight: 400 !important;
}

.easy-button-container {
    width: 30px;
    height: 30px;
}

.easy-button-container button {
    background-color: white;
    width: 41px;
    height: 41px;
    border: none;
    border-radius: 5px;
    color: #999;
}

.easy-button-container button:hover {
    background-color: #f4f4f4;
}

.leaflet-control-zoom {
    margin-top: 80px !important;
}

.resize-button {
    font-size: 18px !important;
    margin-top: 2px !important;
}

/* #sctabimg {
    left: 15px !important;
    width: 80px !important;
    height: 30px !important;
    border-radius: 5px !important;
    box-shadow: rgba(153, 153, 153, 0.14) 0px 2px 2px 0px, rgba(153, 153, 153, 0.2) 0px 3px 1px -2px, rgba(153, 153, 153, 0.12) 0px 1px 5px 0px !important;
    background-size: 100px !important;
    background-position: -4px -5px !important;
}

#sctabimg > div {
    left: 55px !important;
    top: 6px !important;
    height: 15px !important;
} */

#counter_cair {
    z-index: 100000 !important;
    position: absolute !important;
    left: 15px !important;
    bottom: 5px !important;
}
#current_counter_cair {
    z-index: 100000 !important;
    position: absolute !important;
    left: 15px !important;
    bottom: 40px !important;
}
.leaflet-container .leaflet-control-search {
    margin-top: 10px !important;
    border: none !important;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12) !important;
    /* width: 41px;
    height: 41px; */
}

/* .leaflet-control-search .search-button {
    width: 41px !important;
    height: 41px !important;
} */

.leaflet-control-search .search-input {
    border: none !important;
}

.leaflet-control-search .search-tooltip {
    background-color: transparent !important;
    box-shadow: none !important;
}

.leaflet-control-search .search-tip {
    background-color: #fff !important;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12) !important;
    padding: 5px !important;
}

.icon {
    height: 35px;
    width: 35px;
    margin: auto; /* Important */ 
    text-align: center; 
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    padding: 25% 0;
    border: 3px solid #ffffff;
}